import * as React from "react"
 
type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  className?: string
}
 
const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className = "", ...props }, ref) => {
    return (
      <input
        className={`
          flex h-10 w-full rounded-md border border-slate-200 bg-white px-3 py-2 text-sm text-slate-900
          ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium 
          placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 
          focus-visible:ring-slate-950 focus-visible:ring-offset-2 
          disabled:cursor-not-allowed disabled:opacity-50
          dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 
          dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300
          dark:text-white
          ${className}
        `}
        ref={ref}
        {...props}
      />
    )
  }
)

Input.displayName = "Input"
 
export { Input }